import React, { useCallback, useState } from 'react';
import { backfillSubscriptionMeta, backfillSubscriptionUserLinks } from '@src/utils/mmc-api/typedApi';

const DeveloperView = () => {

    const [bfSubUserLinkLoading, setBfSubUserLinkLoading] = useState(false);
    const onBfSubUserLink = useCallback(() => {
        if (!bfSubUserLinkLoading) {
            setBfSubUserLinkLoading(true);
            backfillSubscriptionUserLinks().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubUserLinkLoading(false);
            });
        }
    }, [bfSubUserLinkLoading])

    const [bfSubMetaLoading, setBfSubMetaLoading] = useState(false);
    const onBfSubMeta = useCallback(() => {
        if (!bfSubUserLinkLoading) {
            setBfSubMetaLoading(true);
            backfillSubscriptionMeta().then((f) => {
                alert(f.message);
            }).finally(() => { 
                setBfSubMetaLoading(false);
            });
        }
    }, [bfSubUserLinkLoading])

    return (
        <div className='container'>
            <button onClick={onBfSubUserLink} className="w-nav-brand sign-in-badge">
                {bfSubUserLinkLoading ? 'Loading...' : 'Backfill Subscription User Link'}
            </button>

            <button onClick={onBfSubMeta} className="w-nav-brand sign-in-badge">
                {bfSubMetaLoading ? 'Loading...' : 'Backfill Subscription Meta'}
            </button>
        </div>
    )
};

export default DeveloperView;
