import React, { useEffect, useState } from 'react';
import { Layout, SEO } from '@src/components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../../store/user';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import './styles.css';
import { TabContent, TabNavItem } from '../../components/UI/Tabs';
import NewsletterView from 'components/Admin/NewsletterView';
import ChallengeQuestionsResponsesView from 'components/Admin/ChallengeQuestionsResponses';
import ChallengeQuestionsView from 'components/Admin/ChallengeQuestions';
import PurchasedDocsTable from '@src/components/Admin/PurchasedDocsTable/PurchasedDocsTable';
import SubscriptionTable from '@src/components/Admin/SubscriptionTable/SubscriptionTable';
import DeveloperView from '@src/components/Admin/DeveloperView/DeveloperView';


const AdminPage = () => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('tab1');

  useEffect(() => {
    (async function () {
      if (!user) await dispatch(fetchUser());
      if (user.role !== 'Admin') return (window.location = '/');
      // if (!['admin', 'viewer', ].includes(user?.role.toLowerCase())) return (window.location = '/');
    })();
  }, []);

  return (
    <Layout>
      <SEO title="Admin!" />
      {user?.role == 'Admin' && (
        <div className="Tabs">
          <div className="nav">
            <TabNavItem
              title="Purchased Documents"
              id="tab1"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Subscriptions"
              id="tab2"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Newsletter"
              id="tab3"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Challenge Questions"
              id="tab4"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Challenge Questions Responses"
              id="tab5"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Developer View"
              id="tab6"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div className="outlet">
            <TabContent id="tab1" activeTab={activeTab}>
              <PurchasedDocsTable />
            </TabContent>
            <TabContent id="tab2" activeTab={activeTab}>
              <SubscriptionTable />
            </TabContent>
            <TabContent id="tab3" activeTab={activeTab}>
              <NewsletterView />
            </TabContent>
            <TabContent id="tab4" activeTab={activeTab}>
              <ChallengeQuestionsView />
            </TabContent>
            <TabContent id="tab5" activeTab={activeTab}>
              <ChallengeQuestionsResponsesView />
            </TabContent>
            <TabContent id="tab6" activeTab={activeTab}>
              <DeveloperView />
            </TabContent>
          </div>
        </div>
      )}
    </Layout>
  );
};




export default AdminPage;
