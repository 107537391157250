import { Table } from "@src/components/Table";
import LoaderOverlay from "@src/components/UI/LoaderOverlay";
import * as styles from "./SubscriptionTable.module.css";
import { getAllSubscriptions } from "@src/utils/mmc-api/typedApi";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import SubscriptionTableOverviewCard from "./OverviewCard/OverviewCard";
import { capitalizeFirstLetter } from "@src/utils/string";

const pageSizes = [10, 20, 50];

const SubscriptionTable = () => {
    const inputRef = useRef(null);
    const [filter, setFilter] = useState({ type: '', value: '' });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
  
    const headers = [
      {
        label: 'Date',
        value: 'created_at',
      },
      {
        label: 'Name',
        value: ['name', 'username'],
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Start Date',
        value: 'start_date',
      },
      {
        label: 'End Date',
        value: 'end_date',
      },
      {
        label: 'Price',
        value: 'price',
      },
      {
        label: 'Subscription',
        value: 'sub_plan',
      },
    ];
  
    const { data, isLoading, isFetching } = useQuery(
      ['subscriptions', pageSize, pageNum, filter.value],
      () => getAllSubscriptions(pageSize, pageNum, filter),
      { staleTime: 60000 },
    );

    console.log(data);
  
    const handlePageNumChange = (value) => {
      setPageNum(value);
    };
  
    const handlePageSizeChange = (value) => {
      setPageSize(value);
      setPageNum(1);
    };
  
    const handleChangeFilterType = (value) => {
      if (filter.type && inputRef?.current) {
        inputRef.current.value = '';
      }
  
      setFilter({ type: value, value: '' });
      setPageNum(1);
    };
  
    const debouncedCb = useCallback(
      debounce((fn) => fn(), 750),
      [],
    );
  
    const onFilterChange = (e) => {
      let value = '';
  
      if (filter.type == 'date') {
        if (!e?.[0] || !e?.[1]) {
          return;
        }
        value = `${e?.[0]?.format('YYYY-MM-DD').toString()} 00:00:00|${e?.[1]
          ?.format('YYYY-MM-DD')
          .toString()} 23:59:59`;
      } else {
        value = e.target.value;
      }
  
      debouncedCb(() => {
        setFilter((state) => ({ ...state, value }));
        setPageNum(1);
      });
    };
  
    return (
      <div className="container">
        {(isLoading || isFetching) && <LoaderOverlay />}
        <h2 className="pr-1 pl-1">Subscriptions</h2>
        {
          data ? (
            <div className={styles.totalsContainer}>
              <SubscriptionTableOverviewCard 
                title="Total"
                earned={data?.totalAmount ? `£${data.totalAmount.toFixed(2)}` : "£0"}
                cancelled={data.totalCancelling}
                recurring={data.totalRecurring}
                subscriptions={data.totalResults}
              />
              {
                data.terms ? data.terms.map((term) => {
                  return (
                    <SubscriptionTableOverviewCard 
                      title={`${term.interval_count} ${capitalizeFirstLetter(term.type)}${term.interval_count > 1 ? 's' : ''}`}
                      earned={term.total_amount ? `£${term.total_amount.toFixed(2)}` : "£0"}
                      cancelled={term.total_cancelling}
                      recurring={term.total_recurring}
                      subscriptions={term.total}
                    />
                  )
                }) : null
              }
            </div>
          ) : null}
        <div className="srow x-space-between mt-2 pr-1 pl-1">
          <div className="scolumn 3">
            <label>Filter by</label>
            <select
              className="dropdown"
              value={filter.type}
              onChange={(e) => handleChangeFilterType(e.target.value)}
            >
              <option value=""></option>
              <option value="email">Email</option>
              <option value="subscription">Subscription</option>
              <option value="date">Date</option>
            </select>
          </div>
          {filter.type === 'email' && (
            <div className="scolumn 6">
              <label>Filter Email</label>
              <input className="input" type="text" onChange={onFilterChange} />
            </div>
          )}
          {filter.type === 'subscription' && (
            <div className="scolumn 6">
              <label>Filter Subscription</label>
              <select className="dropdown" onChange={onFilterChange}>
                <option value=""></option>
                <option value="1-month">1-Month</option>
                <option value="3-month">3-Month</option>
                <option value="1-year">1-Year</option>
              </select>
            </div>
          )}
          {filter.type === 'date' && (
            <div className="scolumn 6">
              <label>Filter Date</label>
              <DatePicker
                className="rmdp-mobile"
                mobileButtons={[
                  {
                    label: 'RESET',
                    className: 'rmdp-button rmdp-action-button',
                    // onClick: (e) => onFilterChange(e),
                  },
                ]}
                inputClass="input"
                onChange={onFilterChange}
                range
                rangeHover
                dateSeparator=" to "
              />
            </div>
          )}
          <div className="scolumn 1">
            <label>Page size</label>
            <select
              className="dropdown"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
            >
              {pageSizes.map((size, i) => (
                <option key={i} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Table
          data={data?.data || []}
          headers={headers}
          totalResults={data?.totalResults || 0}
          pageSize={pageSize}
          pageNum={pageNum}
          changePageNum={handlePageNumChange}
        />
      </div>
    );
};

export default SubscriptionTable;
  