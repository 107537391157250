import { Table } from "@src/components/Table";
import LoaderOverlay from "@src/components/UI/LoaderOverlay";
import { generateDatoDocumentsData, getAllPurchases } from "@src/utils/mmc-api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { useSelector } from "react-redux";

const pageSizes = [10, 20, 50];

function SummaryCard({ title, value }) {
    return (
      <div>
        {value && (
          <div
            style={{
              padding: '15px 30px 10px',
              border: 'solid 1px #23d4ff',
              borderRadius: '40px',
            }}
          >
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {title}
            </div>
            <h2
              style={{
                marginTop: '10px',
                textAlign: 'center',
              }}
            >
              {value}
            </h2>
          </div>
        )}
      </div>
    );
}

const PurchasedDocsTable = () => {
    const queryClient = useQueryClient();
    const inputRef = useRef(null);
    const user = useSelector((state) => state.user.data);
    const [filter, setFilter] = useState({ type: '', value: '' });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isGeneratingDatoDocs, setIsGeneratingDatoDocs] = useState(false);
  
    const headers = [
      {
        label: 'Date',
        value: 'created_at',
      },
      {
        label: 'Name',
        value: ['name', 'username'],
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Product name',
        value: 'doc_name',
      },
      {
        label: 'Module',
        value: 'module',
      },
      {
        label: 'Category',
        value: 'category',
      },
      {
        label: 'Price',
        value: 'doc_price',
      },
    ];
  
    const { data, isLoading, isFetching } = useQuery(
      ['purchased-documents', pageNum, pageSize, filter.value],
      () => getAllPurchases(pageSize, pageNum, filter),
      { staleTime: 30000 },
    );
  
    const { mutateAsync } = useMutation(() => generateDatoDocumentsData());
  
    const handlePageNumChange = (value) => {
      setPageNum(value);
    };
  
    const handlePageSizeChange = (value) => {
      setPageSize(value);
      setPageNum(1);
    };
  
    const handleChangeFilterType = (value) => {
      if (filter.type && inputRef?.current) {
        inputRef.current.value = '';
      }
  
      setFilter({ type: value, value: '' });
      setPageNum(1);
    };
  
    const debouncedCb = useCallback(
      debounce((fn) => fn(), 750),
      [],
    );
  
    const onFilterChange = (e) => {
      let value = '';
  
      if (filter.type == 'date') {
        if (!e?.[0] || !e?.[1]) {
          return;
        }
        value = `${e?.[0]?.format('YYYY-MM-DD').toString()} 00:00:00|${e?.[1]
          ?.format('YYYY-MM-DD')
          .toString()} 23:59:59`;
      } else {
        value = e.target.value;
      }
  
      debouncedCb(() => {
        setFilter((state) => ({ ...state, value }));
        setPageNum(1);
      });
    };
  
    const handleGenerateDatoData = async () => {
      setIsGeneratingDatoDocs(true);
  
      const res = await mutateAsync();
  
      if (res?.error) {
        alert(res.error.message);
        setIsGeneratingDatoDocs(false);
        return;
      }
  
      setTimeout(() => {
        queryClient.invalidateQueries(['purchased-documents']);
        setIsGeneratingDatoDocs(false);
      }, 3000);
    };
  
    return (
      <div className="container ">
        {(isLoading || isFetching || isGeneratingDatoDocs) && <LoaderOverlay />}
        <div style={{ display: 'flex', alignItems: 'center' }} className="pr-1 pl-1">
          <h2>Purchased Documents</h2>
          {user?.role == 'Admin' && (
            <button
              disabled={isGeneratingDatoDocs}
              onClick={() => handleGenerateDatoData()}
              className="w-nav-brand sign-in-badge"
            >
              <span>{isGeneratingDatoDocs ? 'Loading...' : 'Generate Dato Docs'}</span>
            </button>
          )}
        </div>
        <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <SummaryCard
            title="Total Earned"
            value={data?.totalAmount ? `£${data.totalAmount.toFixed(2)}` : null}
          />
        </div>
        <div className="srow x-space-between mt-2 pr-1 pl-1">
          <div className="scolumn 3">
            <label>Filter by</label>
            <select
              className="dropdown"
              value={filter.type}
              onChange={(e) => handleChangeFilterType(e.target.value)}
            >
              <option value=""></option>
              <option value="email">Email</option>
              <option value="product">Product</option>
              <option value="module">Module</option>
              <option value="category">Category</option>
              <option value="date">Date</option>
            </select>
          </div>
          {filter?.type && filter.type != 'date' && (
            <div className="scolumn 6">
              <label>{`Filter ${
                filter?.type ? filter.type.charAt(0).toUpperCase() + filter.type.slice(1) : 'Keyword'
              } `}</label>
              <input
                ref={inputRef}
                className="input"
                id="product"
                type="text"
                onChange={onFilterChange}
              />
            </div>
          )}
          {filter?.type && filter.type == 'date' && (
            <div className="scolumn 6">
              <label>Filter Date</label>
              <DatePicker
                className="rmdp-mobile"
                mobileButtons={[
                  {
                    label: 'RESET',
                    type: 'button',
                    className: 'rmdp-button rmdp-action-button',
                  },
                ]}
                inputClass="input"
                onChange={onFilterChange}
                range
                rangeHover
                dateSeparator=" to "
                autoFocus
              />
            </div>
          )}
          <div className="scolumn 1">
            <label>Page size</label>
            <select
              className="dropdown"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
            >
              {pageSizes.map((size, i) => (
                <option key={i} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Table
          data={data?.data || []}
          headers={headers}
          totalResults={data?.totalResults || 0}
          pageSize={pageSize}
          pageNum={pageNum}
          changePageNum={handlePageNumChange}
        />
      </div>
    );
};

export default PurchasedDocsTable;
