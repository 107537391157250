import React from 'react';
import * as styles from './OverviewCard.module.css'

interface SubscriptionTableOverviewCardProps {
    className?: string;
    title: string;
    earned: string;
    subscriptions: number;
    recurring: number;
    cancelled: number;
}

const SubscriptionTableOverviewCard = ({cancelled, earned, recurring, subscriptions, title, className}: SubscriptionTableOverviewCardProps) => {
    return (
        <div className={`${styles.container} ${styles.className}`}>
            <h2>{title}</h2>
            <div className={styles.group}>
                <span className={styles.value}>{earned}</span>
                <span className={styles.label}>Earned</span>
            </div>
            <div className={styles.group}>
                <span className={styles.value}>{subscriptions}</span>
                <span className={styles.label}>Subscriptions</span>
            </div>

            <div className={styles.subGroup}>
                <span className={styles.value}>{recurring}</span>
                <span className={styles.label}>Recurring</span>
            </div>
            <div className={styles.subGroup}>
                <span className={styles.value}>{cancelled}</span>
                <span className={styles.label}>Cancelled</span>
            </div>
        </div>
    )
};

export default SubscriptionTableOverviewCard;
