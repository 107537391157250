const API_URL = process.env.GATSBY_MMC_API_URL; //Strapi instance

function getHeaders() {
    let headers: HeadersInit = {
        'Content-Type': 'application/json',
    };

    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        headers.Authorization = `Bearer ${jwt}`;
    }
    return headers;
}

async function get(url) {
    const headers = getHeaders();
    try {
        const response = await fetch(`${API_URL}/${url}`, { headers });
        if (response.status !== 200) return { success: false };

        return await response.json();
    } catch (error) {
        return { success: false };
    }
}

async function fetchWithBody(method, url, data) {
    method = method.toUpperCase();
    const headers = getHeaders();

    try {
        const response = await fetch(`${API_URL}/${url}`, {
            headers,
            method,
            body: JSON.stringify(data),
        });
        if (response.status !== 200) return null;

        return await response.json();
    } catch (error) {
        return null;
    }
}

const post = async (url, data) => fetchWithBody('post', url, data);


interface createSubscriptionCheckoutSessionsParams {
    priceId: string;
    successUrl: string;
}

type createSubscriptionCheckoutSessionsReturnData = {
    success: true;
    url: string;
} | {
    success: false;
}

export const createSubscriptionCheckoutSession = async ({priceId, successUrl}: createSubscriptionCheckoutSessionsParams): Promise<createSubscriptionCheckoutSessionsReturnData> => {
    return post('stripe/createSubscriptionCheckoutSession', {price_id: priceId, success_url: successUrl}) as unknown as Promise<createSubscriptionCheckoutSessionsReturnData>;
}


type backfillSubscriptionUserLinksReturnData = {
    success: boolean;
    message: string;
}
export const backfillSubscriptionUserLinks = async (): Promise<backfillSubscriptionUserLinksReturnData> => {
    return get('custom-endpoints/backfill-subscription-user-links') as unknown as Promise<backfillSubscriptionUserLinksReturnData>;
}

export const backfillSubscriptionMeta = async (): Promise<backfillSubscriptionUserLinksReturnData> => {
    return get('custom-endpoints/backfill-subscription-meta') as unknown as Promise<backfillSubscriptionUserLinksReturnData>;
}



interface GetAllSubscriptionsRecordData {
    "created_at": string,
    "name": string,
    "username": string,
    "email": string,
    "start_date": string,
    "end_date": string,
    "price": string,
    "sub_plan": string,
    "term": string,
    "cancel_at_period_end": boolean
}

interface GetAllSubscriptionsTermData {
    "interval_count": number,
    "type": string;
    "total": number,
    "total_amount": number,
    "total_cancelling": number,
    "total_recurring": number
}

interface getAllSubscriptionReturnData {
    data: GetAllSubscriptionsRecordData[]
    terms: GetAllSubscriptionsTermData[];
    totalAmount: number;
    totalCancelling: number;
    totalRecurring: number;
    totalResults: number;
}
export const getAllSubscriptions = async (pageSize = 8, pageNum = 1, filter: {type?: string, value?: string} = {}): Promise<getAllSubscriptionReturnData> => {
    let url = `subscriptions/all?pageSize=${pageSize}&pageNum=${pageNum}`;
    if (filter?.type && filter?.value) {
        url += `&type=${filter.type}&filterValue=${filter.value}`;
    }
    return get(url) as unknown as Promise<getAllSubscriptionReturnData>;
};